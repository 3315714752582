<template>
  <sign-page
    title-text="专业技术资格证书管理"
    :request="request"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    @dataFileUpdate="dataFileUpdate"
    :title-menus="[{key: 'add', label: '新增'}]"
    table-size="large"
    :tableActions="tableActions"
    :init-add-form-data="initAddFormData"
    :form-parms-update="formParmsUpdate"
    :tableActionsFixed="true"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  workerCertificateRequest as request
} from '../../api'

import {
  dateOperating
} from '@/fmlib'

import {
  loadData,
  getSelectWorkerList,
  loadSelectWorkerList
} from '../../auth_lib'

export default {
  created () {
    if (this.$authFunsProxy.add || this.$authFunsProxy.edit || this.$authFunsProxy.get || this.$authFunsProxy.getMy || this.$authFunsProxy.getMyOrg || this.$authFunsProxy.getMyOrgContainChild) {
      this.$store.dispatch('loadCertificateList')
    }
    if (this.$authFunsProxy.add || this.$authFunsProxy.edit) {
      loadSelectWorkerList(this)
    }
  },
  computed: {
    tableActions () {
      let data = [{
        key: 'data_file',
        label: '扫描件'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
      return data
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '证书编码',
          key: 'code'
        },
        {
          type: 'select',
          selectDatas: getSelectWorkerList(this),
          label: '所属员工',
          key: 'workerId',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '系列名称/类别',
          key: 'xlmc'
        },
        {
          type: 'input',
          label: '专业名称',
          key: 'pro'
        },
        {
          type: 'select',
          selectDatas: this.$store.getters.certificateList,
          label: '资格证书',
          key: 'certificateId',
          check: {
            required: true
          }
        },
        // {
        //   type: 'input',
        //   label: '管理号',
        //   key: 'gradSchool'
        // },
        {
          type: 'datePicker',
          label: '授予(批准)时间',
          key: 'getDate'
        },
        {
          type: 'input',
          label: '批准文号',
          key: 'practiceType'
        },
        {
          type: 'datePicker',
          label: '发证时间',
          key: 'hireStartDate'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '证书编码',
          sort: true,
          field: 'code'
        },
        // {
        //   title: '管理号',
        //   sort: true,
        //   field: 'gradSchool'
        // },
        {
          title: '员工姓名',
          sort: true,
          field: 'workerName'
        },
        // {
        //   title: '工作单位',
        //   sort: true,
        //   field: 'workerOrg'
        // },
        {
          title: '系列名称/类别',
          width: 150,
          sort: true,
          field: 'xlmc'
        },
        {
          title: '专业名称',
          sort: true,
          field: 'pro'
        },
        {
          title: '资格名称',
          sort: true,
          field: 'certificateTitle',
          filters: this.$store.getters.certificateList.map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '授予(批准)时间',
          sort: true,
          field: 'getDate',
          dataType: Date,
          filterRange: true,
          width: 200,
          render: (h, rowData) => {
            return h('div', rowData && rowData.getDate ? rowData.getDate.slice(0, 10) : '-')
          },
          text: (rowData) => {
            return rowData && rowData.getDate ? rowData.getDate.slice(0, 10) : '-'
          }
        },
        {
          title: '批准文号',
          sort: true,
          field: 'practiceType'
        },
        {
          title: '发证时间',
          sort: true,
          field: 'hireStartDate',
          filterRange: true,
          dataType: Date,
          width: 200,
          render: (h, rowData) => {
            return h('div', rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-')
          },
          text: (rowData) => {
            return rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-'
          }
        }]
        return data
      }
    }
  },
  methods: {
    async dataFileUpdate (parm, pageVm) {
      await request.update(pageVm.chooseData.id, {
        imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'}),
        fileIds: parm ? parm.join(',') : ''
      })
      pageVm.loadData()
    },
    initAddFormData () {
      return {type: 'pro_qua'}
    },
    async loadData () {
      let parm = {
        type: 'pro_qua'
      }
      return await loadData(request, this, parm)
    },
    async updateUploadTime () {
      await request.update(this.choose.id, Object.assign({}, this.choose, {
        imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'})
      }))
      this.pageVm.loadData()
    }
  },
  data () {
    return {
      choose: null,
      loading: false,
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>
